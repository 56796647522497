//Responsive Breakpoints
$breakpoints: (
    'small': 600px,
    'medium': 960px,
    'large': 1280px
);


$form-grid:
    "tiny" 1 2,
    "x-small" 4 2,
    "small" 4 3,
    "medium" 4 4,
    "large" 4 6,
    "x-large" 4 12;


// colors

$white: #ffffff;
$black: #000000;

// Gray

$grayDarkest: #111827;
$grayDarker: #1E293B;
$grayDarkerOpacity: #1E293B80;
$grayDark: #334155;
$gray: #64748B;
$grayLight: #cbd5e1;


// Purple

$purpleDark: #4C1D95;
$purplePrimary: #9333EA;
$purpleLight: #C084FC;

// Blue
$blueDarker: #1E3A8A;
$blueDark: #0E5E81;
$blue: #4F46E5;
$blueLight: #3B82F6;
$blueLighter: #60A5FA;

// Green
$greenDark: #14532D;
$green: #13A55F;
$greenLight: #4ADE80;

// yellow
$yellowDark: #713F12;
$yellow: #fACC15;

// red
$redDark: #7F1D1D;
$red: #DC2626;
$redLight: #F87171;

// orange
$orangeDark: #C35404;
$orange: #E55D36;

// burgundy
$burgundy: #870058;


// brown
$gold: #A06C03;
