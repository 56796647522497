$color-ott-only: #8b4ca4;
$color-linear-and-ott: #5ccbc4;
$color-linear-only: #fb7454;
$color-unexposed: #6fb8ff;

.ad-exposure-chart {
    .highcharts-color-0 {
        fill: $color-ott-only !important;
        stroke: $color-ott-only !important;
    }

    .highcharts-color-1 {
        fill: $color-linear-and-ott !important;
        stroke: $color-linear-and-ott !important;
    }

    .highcharts-color-2 {
        fill: $color-linear-only !important;
        stroke: $color-linear-only !important;
    }

    .highcharts-color-3 {
        fill: $color-unexposed !important;
        stroke: $color-unexposed !important;
    }
}
