@import '../styles/_variables.scss';
.mat-mdc-snack-bar-container.mdc-snackbar {
    &.toast-success {
        --mdc-snackbar-container-color: #4ade80;
        --mdc-snackbar-supporting-text-color: #14532d;
    
        mat-icon {
            color: $greenDark !important;
        }
    }
    
    &.toast-error {
        --mdc-snackbar-container-color: #f87171;
        --mdc-snackbar-supporting-text-color: #7f1d1d;
    
        mat-icon {
            color: $redDark !important;
        }
    }
    
    &.toast-warning {
        --mdc-snackbar-container-color: #facc15;
        --mdc-snackbar-supporting-text-color: #713f12;
    
        mat-icon {
            color: $yellowDark !important;
        }
    }
    
    &.toast-info {
        --mdc-snackbar-container-color: #60a5fa;
        --mdc-snackbar-supporting-text-color: #1E3A8A !important;
    
        mat-icon {
            color: $blueDarker !important;
        }
    }
}

