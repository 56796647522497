@import '../styles/_variables.scss'; 
.home-screen-chart {
    .highcharts-title {
        text-transform: capitalize;
        font-size: 12px;
    }

    .highcharts-tooltip-box {
        stroke-width: 0;
    }

    .highcharts-point {
        fill: inherit !important;
        stroke: inherit !important;
    }

    $observed-colors: [ #ffa600, #ff9100, #ff8000, #f5772d, #ff7c43, #f95d6a, #d4505b, #bd4074,
        #ad3893, ];

    $forecast-colors: [ #29aa37, #43d361, #71f086, #8cf5b1, #4ef8dd, #78e5fe, #00c5ff, #00a0ff,
        #3b71ff, ];

    $incremental-reach-colors: [ #fe9301, #47feb6, #7f7f7f, #ffffff, #6db1ff ];

    $incremental-reach-colors: [
        #fe9301,
        #47feb6,
        #7f7f7f,
        #ffffff,
        #6db1ff,
        #507FA4,
        #32AF7D,
        #05477d,
        #000,
        #a3bbfd
    ];

    $r-f-planning-colors: [
        #8b4ca4,
        #5ccbc4,
        #fb7454,
        #90ee7e,
        #6fb8ff
    ];

    @mixin highcharts-colors {
        @for $i from 1 through length($observed-colors) {
            .highcharts-color-#{($i - 1)} {
                fill: nth($observed-colors, $i);
                stroke: nth($observed-colors, $i);
            }

            .forecast-series-#{($i - 1)} {
                fill: nth($forecast-colors, $i) !important;
                stroke: nth($forecast-colors, $i) !important;
            }

            .observed-series-#{($i - 1)} {
                fill: nth($observed-colors, $i) !important;
                stroke: nth($observed-colors, $i) !important;
            }

            .forecast-series.highcharts-color-#{($i - 1)} {
                fill: nth($forecast-colors, $i);
                stroke: nth($forecast-colors, $i);
            }

            .linked-series-#{($i - 1)} {
                fill: nth($forecast-colors, $i);
                stroke: nth($forecast-colors, $i);
            }

            .reserved-series.highcharts-color-#{($i - 1)} {
                fill: nth($observed-colors, 4);
                stroke: nth($observed-colors, 4);
            }
        }
    }

    @mixin incremental-reach-colors {
        @for $i from 1 through length($incremental-reach-colors) {
            .incremental-series-#{($i - 1)} {
                fill: nth($incremental-reach-colors, $i) !important;
                stroke: nth($incremental-reach-colors, $i) !important;
            }
        }
    }

    @mixin r-f-planning-colors {
        @for $i from 1 through length($r-f-planning-colors) {
            .rfp-series-#{($i - 1)} {
                fill: nth($r-f-planning-colors, $i) !important;
                stroke: nth($r-f-planning-colors, $i) !important;
            }
        }
    }

    .incremental-pattern-fill-0 {
        fill: url(#diagonalHatch0) !important;
        stroke: #45f5ae !important;
    }

    .incremental-pattern-fill-1 {
        fill: url(#diagonalHatch1) !important;
        stroke: #1078cc !important;
    }

    .rf-pattern-fill-0 {
        fill: url(#diagonalHatch2) !important;
        stroke: #90ee7e !important;
    }

    .rf-pattern-fill-1 {
        fill: url(#diagonalHatch3) !important;
        stroke: #6fb8ff !important;
    }

    @include incremental-reach-colors;
    @include r-f-planning-colors;
    @include highcharts-colors;
}

.pacing-chart__container {
    .highcharts-area {
        fill-opacity: 0.35;
    }
    .highcharts-color-0 {
        fill: #4ea35a;
        stroke: #4ea35a;
    }

    .highcharts-color-1 {
        fill: $yellow;
        stroke: $yellow;
    }
}

.tv-chart {
    .highcharts-color-0 {
        fill: #4ea35a;
        stroke: #4ea35a;
    }

    .highcharts-color-1 {
        fill: $yellow;
        stroke: $yellow;
    }

    .highcharts-color-2 {
        fill: #db2a33;
        stroke: #db2a33;
    }

    .highcharts-color-3 {
        fill: #f8f8f8;
        stroke: #f8f8f8;
    }

    .highcharts-color-4 {
        fill: #424242;
        stroke: #424242;
    }

    .milestone-color-0 {
        fill: #f99122;
        stroke: #f99122;
    }

    .milestone-color-1 {
        fill: #84b144;
        stroke: #84b144;
    }

    .milestone-color-2 {
        fill: #e4452e;
        stroke: #e4452e;
    }

    .milestone-color-3 {
        fill: #867d7b;
        stroke: #867d7b;
    }

    .milestone-color-4 {
        fill: #004c6d;
        stroke: #004c6d;
    }

    .highcharts-plot-line-label {
        fill: #e0e0e3;
        font-weight: bold;
    }
}

.reach-curve-chart {
    .highcharts-data-label-box {
        stroke-width: 1;
        stroke: black;
        fill: white;
    }
    .highcharts-data-label > text {
        fill: black;
    }
}

.icon-hover {
    transition-duration: 150ms;
    transition-timing-function: ease-out;

    &:hover {
        color: $purpleLight;
    }
}
