/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import './ad-exposure-chart.scss';
@import './aspen-toast.styles.scss';
@import './aspen-inputs.scss';
@import './custom-charts';
@import './custom-tables';
@import './highcharts.scss';
@import './_variables.scss';
@import './_mixins.scss';
@import 'highlight.js/styles/night-owl.css';


.aspen-card {
    padding: 1.5rem 0.5rem;
    margin-bottom: 1.25rem;
    overflow-x: scroll;
    @include responsive('small') {
        padding: 1.5rem 2rem;
    }

    &--rounded {
        border-radius: 1rem !important;
    }
}

.aspen-icon--green {
    cursor: pointer;
    color: $green;
}

.aspen-icon--yellow {
    color: $yellow;
}

.aspen-icon--red {
    color: $red !important;

    &--hover {
        color: $red !important;
        &:hover {
            cursor: pointer;
        }
    }
}

.aspen-icon--hover-primary {
    &:hover {
        cursor: pointer;
        color: $purplePrimary;
    }
}

.aspen-icon--focus {
    @include focus-visible;
}

.mat-mdc-button,
.mat-mdc-fab,
.mat-mdc-unelevated-button,
.mat-mdc-icon-button,
.mat-mdc-mini-fab,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    line-height: 1 !important;
    .mat-icon {
        color: $white !important;
    }


    &.mat-button-disabled {

        .mat-icon {
            opacity: 0.38 !important;
        }
    }
}

.mat-mdc-fab {
    max-height: 56px;
}

/* Rounded design */
.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button,
.mat-mdc-outlined-button {
    padding: 0 20px !important;
    border-radius: 9999px !important;
}
