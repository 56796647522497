/* 1. Components */
@use 'components/example-viewer';
@use 'components/input';

/* 2. Overrides */
@use 'overrides/highlightjs';
@use 'overrides/perfect-scrollbar';
@use 'overrides/quill';

::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
}
