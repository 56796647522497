@import './_variables.scss';

@mixin info-tooltip($color, $font-size) {
    body.dark .mat-mdc-tooltip {
        background-color: $color;
        font-size: $font-size;
        font-weight: bold;
        padding: 0.5rem;
    }
}

// Media Queries
@mixin responsive($size) {
    $query: map-get($breakpoints, $size);

    @media(min-width: $query) {
        @content;
    }
}

@mixin focus-visible {
   &:focus-visible {
        border: 2px solid $blueLight !important;
        border-radius: 0.5rem;
    }
}